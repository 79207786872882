import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

export class GetTasks {
  static readonly type = '[GetTasks] Get Tasks';
}

export class CreateTask {
  static readonly type = '[CreateTask] Create a new Task';
  constructor(public payload: any) {} // Add payload to accept task data
}

export type TasksStateModel = {
  tasks: any[] | undefined;
};

@State<TasksStateModel>({
  name: 'tasks',
  defaults: {
    tasks: [],
  },
})
@Injectable({ providedIn: 'root' })
export class TasksState {
  api = inject(ApiService);

  @Action(GetTasks)
  getTasks(ctx: StateContext<TasksStateModel>) {
    return this.api.get(`api/tasks`).pipe(
      tap((response: any) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          tasks: response,
        });
      })
    );
  }

  @Action(CreateTask)
  createTask(ctx: StateContext<TasksStateModel>, action: CreateTask) {
    return this.api.post(`api/tasks`, action.payload).pipe(
      tap((response: any) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          tasks: [...(state.tasks || []), response], // Add the new task to the existing tasks array
        });
      })
    );
  }

  @Selector([TasksState])
  static tasks(state: TasksStateModel) {
    return state.tasks;
  }
}
