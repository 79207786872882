import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

export class GetContractors {
  static readonly type = '[GetContractors] Get Contractors';
}

export type ContractorsStateModel = {
  contractors: any[] | undefined;
};

@State<ContractorsStateModel>({
  name: 'contractors',
  defaults: {
    contractors: [],
  },
})
@Injectable({ providedIn: 'root' })
export class ContractorsState {
  api = inject(ApiService);

  @Action(GetContractors)
  getContractors(ctx: StateContext<ContractorsStateModel>) {
    return this.api.get('api/contractors').pipe(
      tap((response: any) => {
        const state = ctx.getState();

        ctx.setState({
          ...state,
          contractors: response,
        });
      })
    );
  }

  @Selector([ContractorsState])
  static contractors(state: ContractorsStateModel) {
    return state.contractors;
  }
}
