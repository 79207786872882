import { Injectable } from '@angular/core';
import { Flags } from '@app/shared/models/orders.model';
import { ApiService } from '@app/shared/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

export class GetAllFlags {
  static readonly type = '[GetAllFlags] Get All';
}

export interface FlagsStateModel {
  flags: Flags[] | undefined;
}

@State<FlagsStateModel>({
  name: 'Flags',
  defaults: {
    flags: [],
  },
})
@Injectable({ providedIn: 'root' })
export class FlagsState {
  constructor(private api: ApiService) {}

  @Action(GetAllFlags)
  getAllFlags(ctx: StateContext<FlagsStateModel>) {
    return this.api.get('api/flagcheckboxes').pipe(
      tap((response: Flags[]) => {
        const state = ctx.getState();

        if (response.length) {
          ctx.setState({
            ...state,
            flags: response,
          });
        }
      })
    );
  }

  @Selector([FlagsState])
  static selectFlags(state: FlagsStateModel) {
    return state.flags;
  }
}
