import { Routes } from '@angular/router';
import { AdminGuardRoute } from './admin.guard';

export const AdminRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/dashboard/dashboard.component').then(
        (c) => c.DashboardComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'region-report',
    loadComponent: () =>
      import('./components/reports/region/region.component').then(
        (c) => c.ReportsComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'gua-report',
    loadComponent: () =>
      import('./components/reports/gua/gua.component').then(
        (c) => c.GuaComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'retoure-report',
    loadComponent: () =>
      import('./components/reports/retoure/retoure.component').then(
        (c) => c.RetoureComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'total-insurance-report',
    loadComponent: () =>
      import(
        './components/reports/total-insurance/total-insurance.component'
      ).then((c) => c.TotalInsuranceComponent),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'config',
    loadComponent: () =>
      import(
        '../../components/app-configuration/app-configuration.component'
      ).then((c) => c.AppConfigurationComponent),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'my-profile',
    loadComponent: () =>
      import('./my-profile/my-profile.component').then(
        (c) => c.MyProfileComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'create-user',
    loadComponent: () =>
      import('./components/create/user/user.component').then(
        (c) => c.CreateUserComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'create-group',
    loadComponent: () =>
      import('./components/create/group/group.component').then(
        (c) => c.CreateGroupComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'experts',
    loadComponent: () =>
      import('./components/experts/experts.component').then(
        (c) => c.ExpertsComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'experts/edit/:id',
    loadComponent: () =>
      import('./components/experts/edit/edit.component').then(
        (c) => c.EditExpertComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'new-order',
    loadComponent: () =>
      import('./components/create/order/order.component').then(
        (c) => c.CreateOrderComponent
      ),
    canMatch: [AdminGuardRoute],
  },
  {
    path: 'sb-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sb/dashboard/dashboard.component').then(
            (c) => c.SBDashboardComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'new-order',
        loadComponent: () =>
          import('../sb/order/create-order/create-order.component').then(
            (c) => c.CreateOrderComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
        //canMatch: [AdminGuardRoute],
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
        //canMatch: [AdminGuardRoute],
      },
    ],
  },
  {
    path: 'sv-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv/dashboard/dashboard.component').then(
            (c) => c.SVDashboardComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
        canMatch: [AdminGuardRoute],
      },
    ],
  },
  {
    path: 'sv-enteiler-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv-enteiler/dashboard/dashboard.component').then(
            (c) => c.EnteilerDashComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
        canMatch: [AdminGuardRoute],
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
        canMatch: [AdminGuardRoute],
      },
    ],
  },
];
