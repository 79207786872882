import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

export class GetHolidays {
  static readonly type = '[GetHolidays] Get Holidays';
}

export type HolidaysStateModel = {
  dates: any[] | undefined;
};

@State<HolidaysStateModel>({
  name: 'holidays',
  defaults: {
    dates: [],
  },
})
@Injectable({ providedIn: 'root' })
export class HolidaysState {
  http = inject(HttpClient);

  @Action(GetHolidays)
  getHolidays(ctx: StateContext<HolidaysStateModel>) {
    return this.http
      .get('https://date.nager.at/api/v3/NextPublicHolidays/AT')
      .pipe(
        tap((response: any) => {
          const state = ctx.getState();

          ctx.setState({
            ...state,
            dates: response,
          });
        })
      );
  }

  @Selector([HolidaysState])
  static holidays(state: HolidaysStateModel) {
    return state.dates;
  }
}
