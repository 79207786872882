import { Routes } from '@angular/router';
import { SbGuardRoute } from './sb.guard';

export const SbRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.SBDashboardComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'my-profile',
    loadComponent: () =>
      import('./my-profile/my-profile.component').then(
        (c) => c.MyProfileComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'task/edit/:id',
    loadComponent: () =>
      import('@app/components/tasklist/edit-task/edit-task.component').then(
        (c) => c.EditTaskComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'order',
    loadComponent: () =>
      import('./order/order.component').then((c) => c.OrderComponent),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'new-order',
    loadComponent: () =>
      import('./order/create-order/create-order.component').then(
        (c) => c.CreateOrderComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'edit/:id',
    loadComponent: () =>
      import('@app/components/edit-order/edit-order.component').then(
        (c) => c.EditOrderComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'view/:id',
    loadComponent: () =>
      import('@app/components/view-order/view-order.component').then(
        (c) => c.ViewOrderComponent
      ),
    canMatch: [SbGuardRoute],
  },
];
