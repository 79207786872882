import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const AdminGuardRoute: CanActivateFn = (route, state) => {
  const auth = JSON.parse(localStorage.getItem('auth') as string);
  const router = inject(Router);

  if (auth && auth.roles && auth.roles.includes('admin')) {
    return true;
  } else {
    router.navigate(['/not-authorized']);
    return false;
  }
};
