import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

export class GetUsers {
  static readonly type = '[GetUsers] Get Users';
}

export type UsersStateModel = {
  users: any[] | undefined;
};

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: [],
  },
})
@Injectable({ providedIn: 'root' })
export class UsersState {
  api = inject(ApiService);

  @Action(GetUsers)
  getUsers(ctx: StateContext<UsersStateModel>) {
    return this.api.get(`api/users/${localStorage.getItem('token')}`).pipe(
      tap((response: any) => {
        const state = ctx.getState();

        ctx.setState({
          ...state,
          users: response,
        });
      })
    );
  }

  @Selector([UsersState])
  static users(state: UsersStateModel) {
    return state.users;
  }
}
