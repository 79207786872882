import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { SbRoutes } from './dashboards/sb/sb-routing.routes';
import { SvRoutes } from './dashboards/sv/sv-routing.routes';
import { EinteilerRoutes } from './dashboards/sv-enteiler/einteiler-routing.routes';
import { AdminRoutes } from './dashboards/admin/admin-routing.routes';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'sb',
    loadComponent: () =>
      import('./dashboards/sb/sb.component').then((c) => c.SBComponent),
    canActivate: [AuthGuard],
    loadChildren: () => SbRoutes,
  },
  {
    path: 'sv',
    loadComponent: () =>
      import('./dashboards/sb/sb.component').then((c) => c.SBComponent),
    canActivate: [AuthGuard],
    loadChildren: () => SvRoutes,
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('./dashboards/admin/admin.component').then(
        (c) => c.AdminComponent
      ),
    loadChildren: () => AdminRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'sv-einteiler',
    loadComponent: () =>
      import('./dashboards/sb/sb.component').then((c) => c.SBComponent),
    canActivate: [AuthGuard],
    loadChildren: () => EinteilerRoutes,
  },
  {
    path: 'not-authorized',
    loadComponent: () =>
      import('./components/not-authorised/not-authorised.component').then(
        (c) => c.NotAuthorisedComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (c) => c.NotFoundComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
