import { Component, OnInit, OnDestroy, signal } from '@angular/core';
import { ConnectivityService } from './shared/services/connectivity.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isOnline = signal<boolean>(true);
  isUserAuthenticated = signal<boolean>(false);
  private readonly avCoreUrl: string = environment.avCore;

  private connectivitySubscription!: Subscription;
  private tokenExpirationSubscription!: Subscription;

  constructor(
    private connectivityService: ConnectivityService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    setInterval(() => {
      this.evaluateToken();
    }, 3500 * 1000);
  }

  ngOnInit() {
    // Set initial online status and display appropriate message after content is loaded
    document.addEventListener('DOMContentLoaded', () => {
      const initialOnlineStatus = this.connectivityService.isOnline();

      this.isOnline.set(initialOnlineStatus);
      if (!initialOnlineStatus) {
        this.showOfflineMessage();
      }
    });

    // Subscribe to online / offline events
    this.connectivitySubscription =
      this.connectivityService.isOnline$.subscribe((status) => {
        this.isOnline.set(status);

        if (!status) {
          this.showOfflineMessage();
        } else {
          this.messageService.clear('tl'); // Clear offline message if online
        }
      });
  }

  ngOnDestroy() {
    this.connectivitySubscription.unsubscribe();
    if (this.tokenExpirationSubscription) {
      this.tokenExpirationSubscription.unsubscribe();
    }
  }

  /**
   * regenerate a new token when its expired
   **/
  evaluateToken() {
    const refreshToken = JSON.parse(
      localStorage.getItem('authToken') as string
    );

    this.http
      .post(
        `${this.avCoreUrl}/api/authenticate/refresh/${refreshToken.refresh_token}`,
        {}
      )
      .subscribe((response: any) => {
        localStorage.setItem('token', response.access_token);
      });
  }

  private showOfflineMessage() {
    this.messageService.add({
      key: 'tl',
      severity: 'error',
      detail:
        'Es gibt keine Internetverbindung. Versuchen Sie es später noch einmal',
      summary: 'Offline',
      life: 200000,
      closable: false,
    });
  }
}
