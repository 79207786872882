import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, catchError, of, tap } from 'rxjs';

import { ApiService } from './api.service';
import { OrdersDataSet } from '../models/orders.model';

import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';

import { MessageService } from 'primeng/api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  public zip = new JSZip();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private http: HttpClient,
    private api: ApiService
  ) {}

  // reload the current page
  public reloadCurrentRoute(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  // save order as zip file and download it
  public createZipWithFile(data: ArrayBuffer, filename: string): void {
    const blob = new Blob([data], { type: 'application/zip' });

    const a: any = document.createElement('a');
    document.body.appendChild(a);

    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // save order in excel format on pc
  public saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export' + EXCEL_EXTENSION);
    this.messageService.add({
      severity: 'success',
      summary: 'Exportieren',
      detail: 'Erfolgreich exportiert',
    });
  }

  public saveAsPdfFile(content: any, fileName: string) {
    const doc = new jsPDF();

    // Check if content is a valid HTML table element
    if (content instanceof HTMLTableElement) {
      const tableData = {
        columns: Array.from(content.querySelectorAll('th')).map(
          (th) => th.textContent?.trim() || ''
        ),
        rows: Array.from(content.querySelectorAll('tr')).map((tr) =>
          Array.from(tr.querySelectorAll('td')).map(
            (td) => td.textContent?.trim() || ''
          )
        ),
      };

      // Filter out empty rows
      const filteredRows = tableData.rows.filter((row) => row.length > 0);

      (doc as any).autoTable({
        head: [tableData.columns],
        body: filteredRows,
        startY: 10,
        margin: { top: 10, left: 10, right: 10, bottom: 20 },
        styles: {
          headStyles: { fillColor: 'white' },
          bodyStyles: { fillColor: 'white' },
        },
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Exportieren',
        detail: 'Erfolgreich exportiert',
      });
    } else {
      console.warn('Unable to export as PDF');
    }

    doc.save(`${fileName}.pdf`);
  }

  // method to format the date
  public formatDateTime(
    date: Date | null | string,
    timezoneOffset?: number
  ): Date | null | string {
    if (!date) return null;

    const newDate = new Date(date);

    // Get the current time information
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentSecond = now.getSeconds();

    // Apply the current time and optional timezone offset
    newDate.setHours(currentHour);
    newDate.setMinutes(currentMinute, currentSecond);

    if (timezoneOffset !== undefined) {
      // Adjust the date based on the provided timezone offset (in minutes)
      newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
    }

    // Increment the date by 1 day
    newDate.setDate(newDate.getDate());

    return newDate;
  }

  // format the date
  formatDate(date: Date, type?: string) {
    // Create a new Date object from the given string
    const formattedDate = new Date(date);

    // Extract day, month, and year
    const day = formattedDate.getUTCDate().toString().padStart(2, '0');
    const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = formattedDate.getUTCFullYear();

    // return the hour or minute or seconds with 2 digits so if the clock is 9 it should return 09
    const currentHour = formattedDate.getUTCHours().toString().padStart(2, '0');
    const currentMinute = formattedDate
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    const currentSecond = formattedDate
      .getUTCSeconds()
      .toString()
      .padStart(2, '0');

    // Return the formatted date
    if (type === 'hh:mm:ss') {
      return `${day}.${month}.${year} ${currentHour}:${currentMinute}:${currentSecond}`;
    }
    return `${day}.${month}.${year}`;
  }

  // lock the order with specific order id
  public lockOrder(id: number) {
    const localStore = JSON.parse(localStorage.getItem('auth') as string);

    this.api
      .put(`api/orders/lock/${id}/${localStore.preferred_username}`, null)
      .subscribe();
  }

  // unlock the order with specific order id
  public unlockOrder(id: number) {
    this.api.put(`api/orders/unlock/${id}`, null).subscribe();
  }

  // open the last edited of viewed order
  public async openLastOrder(): Promise<void> {
    try {
      const authData = JSON.parse(localStorage.getItem('auth') || '{}');
      const token = localStorage.getItem('token');

      if (!authData.sub || !token) {
        throw new Error('Missing authentication data');
      }

      const { sub } = authData;
      const userResponse = await this.api
        .get(`api/users/${sub}`, { token })
        .toPromise();

      if (
        userResponse &&
        userResponse.attribute &&
        userResponse.attribute.attributes &&
        userResponse.attribute.attributes.short
      ) {
        const shortName = userResponse.attribute.attributes.short[0];

        this.api
          .get(`api/orders/getByLastSb/${shortName}`)
          .pipe(
            tap((response: OrdersDataSet) => {
              const currentUrl = this.router.url;

              switch (currentUrl) {
                case '/admin/sb-dashboard':
                  this.router.navigate([
                    '/admin/sb-dashboard/edit',
                    response.avId,
                  ]);
                  this.lockOrder(response.avId);
                  break;
                case '/admin/sv-dashboard':
                  this.router.navigate([
                    '/admin/sv-dashboard/edit',
                    response.avId,
                  ]);
                  this.lockOrder(response.avId);
                  break;
                case '/admin/sv-enteiler-dashboard':
                  this.router.navigate([
                    '/admin/sv-enteiler-dashboard/edit',
                    response.avId,
                  ]);
                  this.lockOrder(response.avId);
                  break;
                case '/sb':
                  this.router.navigate(['/sb/edit', response.avId]);
                  this.lockOrder(response.avId);
                  break;
                case '/sv-einteiler':
                  this.lockOrder(response.avId);
                  this.router.navigate(['/sv-einteiler/edit', response.avId]);
                  break;
                case '/sv':
                  this.router.navigate(['/sv/view', response.avId]);
                  break;
                default:
                  console.error('Unknown URL:', currentUrl);
              }
            }),
            catchError((error) => {
              console.error('Failed to get last order:', error);
              return of(null); // returning a default value or rethrowing the error
            })
          )
          .subscribe();
      } else {
        throw new Error('Short name not found in user attributes');
      }
    } catch (error) {
      console.error('An error occurred while processing last order:', error);
    }
  }

  // get the user role that is authenticated
  public checkIsUserInRole(role: string): boolean {
    let userDetails = JSON.parse(localStorage.getItem('auth') as string);

    if (userDetails.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  public downloadFileFromBase64(base64String: string, fileName: string): void {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    if ((window.navigator as any).msSaveOrOpenBlob) {
      // For IE
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  public sendOrdersMessages<T>(token: string, orders: number[]): Observable<T> {
    return this.api
      .post(`api/orders/sendSms?token=${token}`, {
        orderIds: orders,
      })
      .pipe();
  }

  public sendConfirmedOrdersMessagesByExpertName<T>(
    token: string,
    ids: string[] | string
  ): Observable<T> {
    return this.api
      .post(`api/orders/sendConfirmedSmsByExpertNames?token=${token}`, {
        guaSvIds: ids,
      })
      .pipe();
  }

  /**
   * Send an email using the mailto link.
   */
  sendEmail(to: string, subject: string, body: any): void {
    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  /**
   * Send an email message to a user with a list of selected order IDs.
   */
  public sendEmailMessageToUser(
    to: string,
    message: string,
    subject: string,
    selectedOrders: any[]
  ): void {
    // Extract avIds from the selected orders
    const avIds = selectedOrders
      .filter((order) => order.avId)
      .map((order) => order.avId);

    // Get the base URL
    const baseUrl = window.location.origin + '/sb';

    // Create the HTML body message with anchor tags
    const bodyMessage: string = avIds
      .map((avId) => {
        const orderUrl = `${baseUrl}/edit/${avId}`;
        // Wrap the URL in an anchor tag with the URL as the href attribute
        return `${orderUrl}\n`;
      })
      .join('');

    // Send the email
    this.sendEmail(
      'retour@schadenlogistik.com',
      `Rückgabe von: ${JSON.parse(
        localStorage.getItem('userLogedInUsername') as string
      )}`,
      `AV-IDs:\n ${bodyMessage}`
    );

    // Log the action to the API
    this.api
      .post(`api/order-history`, {
        orderIds: selectedOrders
          .filter((order) => order.avId)
          .map((order) => order.avId),
        timestamp: new Date(),
        action: 'Av-IDs wurden per E-Mail verschickt',
        username: JSON.parse(
          localStorage.getItem('userLogedInUsername') as string
        ),
      })
      .subscribe();
  }
}
