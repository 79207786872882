<div class="flex sm:gap-8 bg-white h-screen">
  <div class="hidden lg:flex w-[35dvw] items-center justify-center">
    <div class="flex items-center flex-col gap-[50px]">
      <img
      ngSrc="assets/images/koppensteiner-logo.png"
        alt="logo"
        class="object-contain"
        priority
        height="36"
        width="125"
      />
      <img
      ngSrc="assets/images/assecu-logo.png"
        alt="logo"
        class="object-contain"
        priority
        height="36"
        width="125"
      />
      <img
      ngSrc="assets/images/damage-kontrol-logo.png"
        alt="logo"
        class="object-contain"
        priority
        height="36"
        width="125"
      />
    </div>
  </div>

  <div
    class="flex flex-col flex-grow justify-center items-center lg:bg-gradient-to-b from-[#374352] to-[#212932]"
  >
    <div
      class="flex lg:hidden items-center justify-center gap-7 flex-wrap mb-4"
    >
      <img
        ngSrc="assets/images/koppensteiner-logo.png"
        alt="koppensteiner logo"
        priority
        height="55"
        width="100"
      />
      <img
        ngSrc="assets/images/assecu-logo.png"
        alt="assecu logo"
        priority
        height="55"
        width="100"
      />
      <img
        ngSrc="assets/images/damage-kontrol-logo.png"
        alt="damage-kontrol logo"
        priority
        height="55"
        width="100"
      />
    </div>

    <div class="flex flex-col w-full sm:w-[400px] px-6 sm:px-4">
      <div class="flex flex-col">
        <h1 class="mt-4 mx-auto sm:mx-0 font-semibold lg:text-white text-2xl">
          Anmeldung bei ihrem Konto
        </h1>
        <p
          class="mb-8 text-base font-normal text-gray-500 mx-auto sm:mx-0 lg:text-gray-300"
        >
          Willkommen zurück!
        </p>
      </div>

      @if((loginForm.controls['username'].invalid &&
      loginForm.controls['username'].touched) ||
      (loginForm.controls['password'].invalid &&
      loginForm.controls['password'].touched) || errors.length > 0) {

      <ng-container>
        <ul
          class="list-inside transition-all p-4 rounded-md mb-6 list-disc bg-red-50 border border-red-400 text-red-600"
        >
          @if (loginForm.controls['username'].invalid &&
          loginForm.controls['username'].touched) {
          <li class="text-red-600 transition-all">
            Benutzername ist erforderlich
          </li>
          } @if (loginForm.controls['password'].invalid&&
          loginForm.controls['password'].touched) {
          <li class="text-red-600 transition-all">Passwort ist erforderlich</li>
          }
          <li *ngIf="errors.length > 0" class="text-red-600 transition-all">
            {{ errors }}
          </li>
        </ul>
      </ng-container>
      }

      <form
        class="flex flex-col gap-4"
        (ngSubmit)="onLogin()"
        [formGroup]="loginForm"
        novalidate
      >
        <div class="relative">
          <i
            class="material-symbols-outlined text-[26px] absolute top-[14px] left-4 text-gray-500"
            >person</i
          >
          <input
            [autofocus]="false"
            pInputText
            class="ps-14 py-3 border-2 text-base disabled:pointer-events-none disabled:opacity-75"
            placeholder="Benutzername"
            type="text"
            id="username"
            formControlName="username"
            required
          />
        </div>
        <div class="relative">
          <i
            class="material-symbols-outlined text-[26px] absolute top-[14px] left-4 text-gray-500"
            >password</i
          >
          <input
            [autofocus]="false"
            pInputText
            class="px-14 py-3 border-2 text-base"
            placeholder="Passwort"
            formControlName="password"
            required
            id="password"
            type="{{ showPws ? 'text' : 'password' }}"
          />
          <div
            class="cursor-pointer z-50 absolute top-[16px] right-4 text-gray-500"
            (click)="onShowPassword()"
          >
            @if (showPws) {
            <i class="material-symbols-outlined text-gray-500"
              >visibility_off</i
            >
            } @else {
            <i class="material-symbols-outlined text-gray-500">visibility</i>
            }
          </div>
        </div>

        <div class="mt-4">
          <button
            class="ui-btn btn-primary w-full justify-center disabled:bg-blue-600/50"
            type="submit"
            [disabled]="submited()"
          >
            @if(submited()){
            <span class="material-symbols-outlined text-base animate-spin"
              >sync</span
            >
            <span>Anmelden</span>
            } @else {
            <span>Anmeldung</span>
            }
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
