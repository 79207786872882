import { Routes } from '@angular/router';
import { SvGuardRoute } from './sv.guard';

export const SvRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.SVDashboardComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'my-profile',
    loadComponent: () =>
      import('./my-profile/my-profile.component').then(
        (c) => c.MyProfileComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'gua/:id',
    loadComponent: () =>
      import('./gua-task/detail/gua-detail.component').then(
        (c) => c.GuaDetailComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'view/:id',
    loadComponent: () =>
      import('../../components/view-order/view-order.component').then(
        (c) => c.ViewOrderComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'task/edit/:id',
    loadComponent: () =>
      import('@app/components/tasklist/edit-task/edit-task.component').then(
        (c) => c.EditTaskComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'technical-news/edit/:id',
    loadComponent: () =>
      import('./technical-news/edit-news/edit-news.component').then(
        (c) => c.EditNewsComponent
      ),
    canMatch: [SvGuardRoute],
  },
];
