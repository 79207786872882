// auth.guard.ts
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const AuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  if (typeof window !== 'undefined') {
    const auth = localStorage.getItem('auth');

    if (auth) {
      const parsedAuth = JSON.parse(auth);
      if (parsedAuth && parsedAuth) {
        return true;
      }
    }
  }

  router.navigate(['/login']);
  return false;
};
