import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private sanitizer: DomSanitizer) {}

  sanitiseImage(image: SafeResourceUrl, mimeType: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/${mimeType};base64,${image}`
    );
  }

  getSanitizedImage(imageData: string, mimeType: string): SafeResourceUrl {
    return this.sanitiseImage(imageData, mimeType);
  }
}
