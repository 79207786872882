import { Routes } from '@angular/router';
import { EinteilerGuardRoute } from './einteiler.guard';

export const EinteilerRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.EnteilerDashComponent
      ),
    canMatch: [EinteilerGuardRoute],
  },
  {
    path: 'my-profile',
    loadComponent: () =>
      import('./my-profile/my-profile.component').then(
        (c) => c.MyProfileComponent
      ),
    canMatch: [EinteilerGuardRoute],
  },
  {
    path: 'edit/:id',
    loadComponent: () =>
      import('@app/components/edit-order/edit-order.component').then(
        (c) => c.EditOrderComponent
      ),
    canMatch: [EinteilerGuardRoute],
  },
  {
    path: 'view/:id',
    loadComponent: () =>
      import('@app/components/view-order/view-order.component').then(
        (c) => c.ViewOrderComponent
      ),
    canMatch: [EinteilerGuardRoute],
  },
  {
    path: 'task/edit/:id',
    loadComponent: () =>
      import('@app/components/tasklist/edit-task/edit-task.component').then(
        (c) => c.EditTaskComponent
      ),
    canMatch: [EinteilerGuardRoute],
  },
];
